import { takeLatest, put, select } from "redux-saga/effects";
import slice, {
  FETCH_ENGINE_IDLE_PERCENT,
} from "store/dashboard/engine-idle/percent/slice";
import { getIdlePercentForCompany } from "resources/services/dashboardService";
import { selectMyUser } from "store/account/selectors";

function* fetch({ payload, meta }) {
  const { lastN } = payload;
  const { error, success, fetching } = slice.actions;
  const { cid } = yield select(selectMyUser);

  yield put(fetching());

  try {
    const response = yield getIdlePercentForCompany(cid, lastN);
    yield put(success(response));
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchEngineIdlePercent() {
  yield takeLatest(FETCH_ENGINE_IDLE_PERCENT.type, fetch);
}
