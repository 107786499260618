import { combineReducers } from "redux";
import { fork } from "redux-saga/effects";
import engineIdlePercentSlice from "./engine-idle/percent/slice";
import engineIdlePercentGroupSlice from "./engine-idle/groupPercent/slice";
import engineIdleSlice from "./engine-idle/idle/slice";
import tripCompanySlice from "./trip/slice";
import distanceCompanySlice from "./distance/slice";
import watchFetchEngineIdlePercent from "./engine-idle/percent/sagas/fetchEngineIdlePercent";
import watchFetchEngineIdlePercentGroup from "./engine-idle/groupPercent/sagas/fetchEngineIdlePercentGroup";
import watchFetchEngineIdleCompany from "./engine-idle/idle/sagas/fetchEngineIdleCompany";
import watchFetchTripCompany from "./trip/sagas/fetchTripCompany";
import watchFetchDistanceCompany from "./distance/sagas/fetchDistanceCompany";

export default combineReducers({
  engineIdlePercent: engineIdlePercentSlice.reducer,
  engineIdlePercentGroup: engineIdlePercentGroupSlice.reducer,
  engineIdleCompany: engineIdleSlice.reducer,
  tripCompany: tripCompanySlice.reducer,
  distanceCompany: distanceCompanySlice.reducer,
});

export function* watchDashboard() {
  yield fork(watchFetchEngineIdlePercent);
  yield fork(watchFetchEngineIdlePercentGroup);
  yield fork(watchFetchEngineIdleCompany);
  yield fork(watchFetchTripCompany);
  yield fork(watchFetchDistanceCompany);
}
