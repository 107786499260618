import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityState, defaultEntityReducers } from "store/defaults";
import purge from "store/purge";

const initialState = {
  isLoading: false,
  error: undefined,
  data: [],
};

export default createSlice({
  name: "DASHBOARD/ENGINE_IDLE/PERCENT",
  initialState,
  reducers: {
    ...defaultEntityReducers,
    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload; // TODO: update this
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_ENGINE_IDLE_PERCENT = createAction(
  "FETCH_ENGINE_IDLE_PERCENT"
);
