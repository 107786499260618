import { takeLatest, put } from "redux-saga/effects";
import accountSlice, { FETCH_MY_USER } from "store/account/slice";
import { getMyUser } from "resources/services/userService";

function* fetch({ payload }) {
  const { onSuccess } = payload || {};
  const { error, fetching, success, setMyUser } = accountSlice.actions;

  yield put(fetching());

  try {
    const response = yield getMyUser();

    onSuccess && onSuccess(response);
    yield put(setMyUser(response));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchUser() {
  yield takeLatest(FETCH_MY_USER.type, fetch);
}
