import client from "configurations/fetch";

export const getIdlePercentForCompany = (cid, lastN) => {
  return client.get(`/Dashboard/IdlePercent/Company?cid=${cid}&lastN=${lastN}`);
};
export const getIdlePercentForCompanyGroup = (cid, lastN) => {
  return client.get(
    `/Dashboard/IdlePercentGroup/Company?cid=${cid}&lastN=${lastN}`
  );
};
export const getIdleForCompany = (cid, lastN) => {
  return client.get(`/Dashboard/Idle/Company?cid=${cid}&lastN=${lastN}`);
};
export const getTripForCompany = (cid, lastN) => {
  return client.get(`/Dashboard/Trip/Company?cid=${cid}&lastN=${lastN}`);
};
export const getDistanceForCompany = (cid, lastN) => {
  return client.get(`/Dashboard/Distance/Company?cid=${cid}&lastN=${lastN}`);
};
