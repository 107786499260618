import { createSlice } from '@reduxjs/toolkit';
import purge from 'store/purge';

const defaultState = {
  isShowingSidebar: true,
  isShowingSidebarPadding: true,
};

export default createSlice({
  name: 'SIDEBAR_UI',
  initialState: defaultState,
  reducers: {
    showSidebar: state => {
      state.isShowingSidebar = true;
    },

    hideSidebar: state => {
      state.isShowingSidebar = false;
    },

    showSidebarPadding: state => {
      state.isShowingSidebarPadding = true;
    },

    hideSidebarPadding: state => {
      state.isShowingSidebarPadding = false;
    },

    setIsShowingSidebar: (state, { payload }) => {
      state.isShowingSidebar = payload;
    },

    toggleSidebar: state => {
      state.isShowingSidebar = !state.isShowingSidebar;
    },
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
