import { createSlice } from "@reduxjs/toolkit";
import purge from "store/purge";

const defaultState = {
  recoveryMode: {
    aid: null,
    open: false,
  },
  paymentFailed: {
    aid: null,
    open: false,
  },
};

export default createSlice({
  name: "MODAL_UI",
  initialState: defaultState,
  reducers: {
    setRecoveryModeModal: (state, { payload }) => {
      state.recoveryMode = { ...state.recoveryMode, ...payload };
    },
    setPaymentFailedModal: (state, { payload }) => {
      state.paymentFailed = { ...state.paymentFailed, ...payload };
    },
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
