import { takeLatest, put, all, select } from "redux-saga/effects";
import accountSlice, { IMPERSONATE_USER } from "store/account/slice";
import { getImpersonationToken } from "resources/services/tokenService";
import { loginRequests } from "./login";
import { persistor } from "configurations/redux";
import { PURGE } from "store/rootReducer";
import jwtDecode from "jwt-decode";
import { getUserMapSettings } from "resources/services/userService";
import { showSnackbar } from "store/ui/snackbar/slice";
import {
  selectAccessToken,
  selectExpiresAt,
  selectIsMitrailSite,
  selectMyUser,
} from "../selectors";
import stripeSlice from "store/stripe/customer/slice";
import deviceSlice from "store/device/slice";
import { createStripeCustomer } from "resources/services/stripeService";
import getClientError from "utilities/getClientError";
import UserRoles from "utilities/enumerations/UserRoles";
import { getAllDeviceTypes } from "resources/services/deviceService";
import normalize from "utilities/array/normalize";
import getCountryCode from "utilities/getCountryCode";

function* impersonate({ payload, meta }) {
  const isMitrailSite = yield select(selectIsMitrailSite);
  const { setStripeCustomer } = stripeSlice.actions;
  const { setDeviceInfo } = deviceSlice.actions;

  const { uid, cid } = payload;
  const {
    fetching,
    success,
    error,
    setAccessToken,
    setExpiresAt,
    setDocuments,
    setExpiresAtASP,
    setAccessTokenASP,
    setMyUser,
    setMyCompany,
    setPermissions,
    setMapSettings,
  } = accountSlice.actions;

  try {
    const token = yield getImpersonationToken(uid, cid);
    const expiresAt = JSON.stringify(
      token.expires_in * 1000 + new Date().getTime()
    );

    const accessTokenASP = yield select(selectAccessToken);
    const expiresAtASP = yield select(selectExpiresAt);

    yield put(PURGE());
    yield persistor.flush();
    yield persistor.persist();

    yield put(fetching());

    // store the ASP access data so we can use this to leave impersonation
    yield put(setExpiresAtASP(expiresAtASP));
    yield put(setAccessTokenASP(accessTokenASP));

    const decodedToken = jwtDecode(token.access_token);

    yield put(setExpiresAt(expiresAt));
    yield put(setAccessToken(token.access_token));
    yield put(setPermissions(JSON.parse(decodedToken.permissions)));

    const [documents, user, company] = yield all(loginRequests);

    company.country = getCountryCode(company.country);

    yield put(setDocuments(documents));
    yield put(setMyUser(user));
    yield put(setMyCompany(company));

    // NOTE: if on mitrail site, lets also fetch/create there stripe customer
    if (isMitrailSite && user?.role !== UserRoles.TemporaryUser) {
      const stripeCustomer = yield createStripeCustomer({
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });

      yield put(setStripeCustomer(stripeCustomer));
    }

    yield put(success());
    meta?.onSuccess && meta.onSuccess();
  } catch (e) {
    const errorMessage = getClientError(e);

    yield put(error(errorMessage));
    meta?.onError && meta.onError(errorMessage);
    yield put(showSnackbar({ message: errorMessage, severity: "error" }));
  }

  const myUser = yield select(selectMyUser);

  // NOTE: silently fail if any of the following requests don't go through as they aren't necessary for login

  try {
    // NOTE: retrieve user's map settings
    const mapSettings = yield getUserMapSettings(myUser.uid);

    if (mapSettings) {
      yield put(setMapSettings(mapSettings));
    }
  } catch (e) {
    console.error("Unable to retrieve user map settings.");
  }

  try {
    // NOTE: retrieve data on all the devices we offer
    const deviceInfo = yield getAllDeviceTypes();
    const normalizedDevices = normalize(deviceInfo, "type");

    if (normalizedDevices) {
      yield put(setDeviceInfo(normalizedDevices));
    }
  } catch (e) {
    console.error("Unable to retrieve all device information.");
  }
}

export default function* watchImpersonateUser() {
  yield takeLatest(IMPERSONATE_USER.type, impersonate);
}
