import { fork } from "redux-saga/effects";
import watchUpdateMyUser from "./updateMyUser";
import watchFetchMyAvatar from "./fetchMyAvatar";
import watchLogin from "./login";
import watchRegister from "./register";
import watchResetPassword from "./resetPassword";
import watchAcceptTerms from "./acceptTerms";
import watchAcceptTrackingTerms from "./acceptTrackingTerms";
import watchRejectTerms from "./rejectTerms";
import watchResendRegisterEmail from "./resendRegisterEmail";
import watchRejectTrackingTerms from "./rejectTrackingTerms";
import watchImpersonateUser from "./impersonateUser";
import watchFetchMyUser from "./fetchMyUser";
import watchUpdateTutorials from "./updateTutorials";
import watchUpdateMapSettings from "./updateMapSettings";
import watchUpdateToken from "./updateToken";
import watchLeavImpersonation from "./leaveImpersonation";
import watchFetchCompany from "./fetchMyCompany";
import watchUpdateMyCompany from "./updateMyCompany";

export default function* accountSaga() {
  yield fork(watchFetchMyAvatar);
  yield fork(watchLogin);
  yield fork(watchRegister);
  yield fork(watchUpdateMyUser);
  yield fork(watchUpdateTutorials);
  yield fork(watchUpdateMapSettings);
  yield fork(watchResetPassword);
  yield fork(watchAcceptTerms);
  yield fork(watchAcceptTrackingTerms);
  yield fork(watchRejectTerms);
  yield fork(watchRejectTrackingTerms);
  yield fork(watchResendRegisterEmail);
  yield fork(watchImpersonateUser);
  yield fork(watchLeavImpersonation);
  yield fork(watchUpdateToken);
  yield fork(watchFetchMyUser);
  yield fork(watchFetchCompany);
  yield fork(watchUpdateMyCompany);
}
