import { isAuthenticated, getAccessToken } from "utilities/authorization";
import { API_URL, ORIGIN } from "./appConfig";
import merge from "lodash/merge";

const getOptions = (options) => {
  let mergedOptions = merge(
    {
      headers: {
        "Accept-Language": localStorage.getItem("language"),
        "Content-Type": "application/json",
        "X-HostName": ORIGIN,
        Authorization: isAuthenticated() ? `Bearer ${getAccessToken()}` : "",
      },
    },
    options
  );

  !mergedOptions.headers.Authorization &&
    delete mergedOptions.headers.Authorization;

  return mergedOptions;
};

export default {
  post: (url, data, options = {}) => {
    return doFetch(
      url,
      getOptions({ ...options, body: parseData(data, options) }),
      "POST"
    );
  },

  get: (url, data, options = {}) => {
    return doFetch(
      url,
      getOptions({ ...options, body: parseData(data, options) }),
      "GET"
    );
  },

  put: (url, data, options = {}) => {
    return doFetch(
      url,
      getOptions({ ...options, body: parseData(data, options) }),
      "PUT"
    );
  },

  delete: (url, data, options = {}) => {
    return doFetch(
      url,
      getOptions({ ...options, body: parseData(data, options) }),
      "DELETE"
    );
  },
};

function parseData(data, options) {
  if (options.headers && options.headers["Content-Type"]) return data;

  return JSON.stringify(data);
}

function removeQuotes(str) {
  if (typeof str !== "string") {
    return str; // Return the input value as-is if it's not a string
  }
  return str.replace(/^["']|["']$/g, "");
}

async function doFetch(_url, options, method) {
  let headers = {};
  const url = API_URL + _url;
  const response = await fetch(url, { ...options, method });

  // Check for 4xx or 5xx HTTP status codes and throw an error
  if (response.status >= 400 && response.status < 600) {
    const text = await response.text();
    throw new Error(removeQuotes(text));
  }

  if (response.status === 204) {
    return;
  }

  for (const [key, value] of response.headers.entries()) {
    headers[key] = value;
  }

  if (!headers["content-type"]) throw new Error(response.statusText);

  if (headers["content-type"].includes("text/plain")) {
    const body = await response.text(); // Fixed this line from response.body to response.text()
    throw new Error(body);
  }

  if (headers["content-type"].includes("application/json")) {
    return response.json();
  }

  const text = await response.text();
  throw new Error(text);
}
