import { takeLatest, put, all, select } from "redux-saga/effects";
import accountSlice, { LEAVE_IMPERSONATION } from "store/account/slice";
import { loginRequests } from "./login";
import { persistor } from "configurations/redux";
import { PURGE } from "store/rootReducer";
import jwtDecode from "jwt-decode";
import { getUserMapSettings } from "resources/services/userService";
import { showSnackbar } from "store/ui/snackbar/slice";
import {
  selectAccessTokenASP,
  selectExpiresAtASP,
  selectIsMitrailSite,
} from "../selectors";
import stripeSlice from "store/stripe/customer/slice";
import { createStripeCustomer } from "resources/services/stripeService";

function* leaveImpersonation({ meta, payload }) {
  const isMitrailSite = yield select(selectIsMitrailSite);
  const { setStripeCustomer } = stripeSlice.actions;

  const {
    fetching,
    success,
    error,
    setAccessToken,
    setExpiresAt,
    setDocuments,
    setMyUser,
    setMyCompany,
    setPermissions,
    setMapSettings,
    setRedirect,
  } = accountSlice.actions;

  try {
    const expiresAtASP = yield select(selectExpiresAtASP);
    const accessTokenASP = yield select(selectAccessTokenASP);

    yield put(PURGE());
    yield persistor.flush();
    yield persistor.persist();

    yield put(fetching());

    const decodedToken = jwtDecode(accessTokenASP);

    yield put(setExpiresAt(expiresAtASP));
    yield put(setAccessToken(accessTokenASP));
    yield put(setPermissions(JSON.parse(decodedToken.permissions)));

    const [documents, user, company] = yield all(loginRequests);
    yield put(setDocuments(documents));
    yield put(setMyUser(user));
    yield put(setMyCompany(company));

    // NOTE: retrieve user's map settings
    const mapSettings = yield getUserMapSettings(user.uid);
    if (mapSettings) {
      yield put(setMapSettings(mapSettings));
    }

    // NOTE: if on mitrail site, lets also fetch/create there stripe customer
    if (isMitrailSite) {
      const stripeCustomer = yield createStripeCustomer({
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });

      yield put(setStripeCustomer(stripeCustomer));
    }

    if (payload?.redirect) {
      yield put(setRedirect(payload.redirect));
    }
    yield put(success());

    meta?.onSuccess && meta.onSuccess();
  } catch (e) {
    yield put(error(e.data));

    yield put(showSnackbar({ message: e.data, severity: "error" }));
  }
}

export default function* watchLeavImpersonation() {
  yield takeLatest(LEAVE_IMPERSONATION.type, leaveImpersonation);
}
