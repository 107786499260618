import React, { useCallback, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN } from "store/account/slice";
import {
  selectIsLoadingAccount,
  selectLanguage,
  selectLoginStatus,
} from "store/account/selectors";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { bootstrap, logo } from "styles/theme";
import useAuthorization from "hooks/authorization/useAuthorization";
import accountSlice from "store/account/slice";
import languageOptions from "components/Select/options/languageOptions";
import { ALLOW_REGISTRATION } from "configurations/appConfig";
import { useForm, Controller } from "react-hook-form";
import useQuery from "hooks/utilities/useQuery";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Link,
  Paper,
  CircularProgress,
  Grid,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Email as EmailIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import Copyright from "components/App/Copyright";
import { showSnackbar } from "store/ui/snackbar/slice";
import ResetPassword from "./ResetPassword/ResetPassword";

export default function LoginForm() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const { isAuthenticated } = useAuthorization();
  const language = useSelector(selectLanguage);
  const loginStatus = useSelector(selectLoginStatus);
  const [prevLanguage, setPrevLanguage] = useState(language);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setLanguage } = accountSlice.actions;

  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [attemptedAutoLogin, setAttemptedAutoLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const isLoadingAccount = useSelector(selectIsLoadingAccount);

  const isProcessingLogin = isLoading || isLoadingAccount;

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
    window.localStorage.setItem("rememberMe", event.target.checked);
  };

  const dispatchLogin = useCallback(
    (data) => {
      console.log(data);
      setIsLoading(true);
      dispatch(
        LOGIN(data, {
          onSuccess: () => history.push("/Assets"),
          onError: (error) => {
            setIsLoading(false);
            dispatch(showSnackbar({ message: error, severity: "error" }));
            setValue("password", "");
          },
        })
      );
    },
    [dispatch, history, setValue]
  );

  const dispatchAutoLogin = useCallback(
    (username, password) => {
      setIsLoading(true);
      setAttemptedAutoLogin(true);
      sessionStorage.setItem("anonymousUserPass", password);
      dispatch(
        LOGIN(
          { username, password },
          {
            onSuccess: () => history.push("/Assets"),
            onError: () => setIsLoading(false),
          }
        )
      );
    },
    [dispatch, history]
  );

  const handleLanguageChange = (event) => {
    const value = event.target.value;
    const selectedOption = languageOptions.find(
      (option) => option.value === value
    );
    localStorage.setItem("language", value);
    dispatch(
      setLanguage({
        label: selectedOption.label,
        value: value,
      })
    );
  };

  useEffect(() => {
    if (language === prevLanguage) return;
    setValue("hidden", "  ");
    setPrevLanguage(language);
  }, [language, prevLanguage, setValue, setPrevLanguage]);

  useEffect(() => {
    const u = query.get("u");
    const p = query.get("p");
    if (!attemptedAutoLogin && u && p) {
      setValue("username", u);
      setValue("password", p);
      dispatchAutoLogin(u, p);
    }
  }, [query, dispatchAutoLogin, attemptedAutoLogin, setValue]);

  if (isAuthenticated && !isLoadingAccount) {
    return <Redirect to="/Assets" />;
  }

  return (
    <Container maxWidth="sm">
      <ResetPassword open={resetPasswordOpen} setOpen={setResetPasswordOpen} />
      <Paper elevation={3} sx={{ p: 4, mt: 8, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Logo" style={{ width: 280, marginBottom: 24 }} />

          <Box
            component="form"
            onSubmit={handleSubmit(dispatchLogin)}
            sx={{ mt: 3, width: "100%" }}
          >
            <Controller
              name="username"
              control={control}
              defaultValue=""
              rules={{
                required: i18n._(t`Email is required`),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: i18n._(t`Invalid email address`),
                },
              }}
              render={({ onChange, value }) => (
                <TextField
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label={i18n._(t`Email Address`)}
                  autoComplete="email"
                  autoFocus
                  variant="outlined"
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  disabled={isProcessingLogin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: i18n._(t`Password is required`) }}
              render={({ value, onChange }) => (
                <TextField
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  margin="normal"
                  required
                  fullWidth
                  label={i18n._(t`Password`)}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  disabled={isProcessingLogin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    disabled={isProcessingLogin}
                  />
                }
                label={
                  <Typography variant="body2">
                    {i18n._(t`Remember me`)}
                  </Typography>
                }
              />
              <Button
                onClick={() => setResetPasswordOpen(true)}
                disabled={isProcessingLogin}
                sx={{ color: "primary", fontSize: 14, textTransform: "none" }}
              >
                {i18n._(t`Forgot password?`)}
              </Button>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: bootstrap.primary }}
              disabled={isProcessingLogin}
            >
              {isProcessingLogin ? (
                <CircularProgress size={24} />
              ) : (
                i18n._(t`LOGIN`)
              )}
            </Button>

            {loginStatus && (
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Typography variant="body2">{loginStatus}...</Typography>
              </Box>
            )}

            {ALLOW_REGISTRATION && (
              <Grid container justifyContent="center">
                <Grid item>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => history.push("/register")}
                    disabled={isProcessingLogin}
                  >
                    {i18n._(t`Don't have an account? Sign Up`)}
                  </Link>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Paper>

      <Box sx={{ mt: 4, textAlign: "center" }}>
        <TextField
          select
          size="small"
          value={language.value}
          onChange={handleLanguageChange}
          variant="outlined"
          label={i18n._(t`Language`)}
          sx={{ minWidth: 260 }}
          disabled={isProcessingLogin}
        >
          {languageOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Copyright />
      </Box>
    </Container>
  );
}
