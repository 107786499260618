import { takeLatest, put } from "redux-saga/effects";
import slice, { REGISTER_DEVICE } from "store/device/slice";
import { registerNewDevice } from "resources/services/deviceService";
import getClientError from "utilities/getClientError";

function* registerDevice({ payload, meta }) {
  const { error, success, fetching } = slice.actions;

  yield put(fetching());
  try {
    const response = yield registerNewDevice(payload);

    if (response?.error) throw new Error(response.error);

    meta?.onSuccess && meta.onSuccess(response);
    yield put(success());
  } catch (e) {
    const message = getClientError(e);
    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export function* watchRegisterDevice() {
  yield takeLatest(REGISTER_DEVICE.type, registerDevice);
}
