import { ORIGIN } from "configurations/appConfig";
import { createSelector } from "@reduxjs/toolkit";

export const selectLoginStatus = (state) => state.account.loginStatus;
export const selectIsLoadingAccount = (state) => state.account.isLoading;
export const selectIsLoadingMyCompany = (state) =>
  state.account.isLoadingMyCompany;

export const selectAccountError = (state) =>
  typeof state.account.error === "string"
    ? state.account.error
    : JSON.stringify(state.account.error);

export const selectMyUser = (state) => state.account.myUser;
export const selectMyCompany = (state) => state.account.myCompany;
export const selectAvatar = (state) => state.account.avatar;
export const selectDocuments = (state) => state.account.documents;
export const selectLanguage = (state) => state.account.language;
export const selectAccessToken = (state) => state.account.accessToken;
export const selectExpiresAt = (state) => state.account.expiresAt;
export const selectAccessTokenASP = (state) => state.account.accessTokenASP;
export const selectExpiresAtASP = (state) => state.account.expiresAtASP;
export const selectPermissions = (state) => state.account.permissions;
export const selectRedirect = (state) => state.account.redirect;

export const selectAnonymousUserPass = (state) =>
  state.account.anonymousUserPass;
export const selectAcceptedTermsAndConditions = (state) =>
  state.account.acceptedTermsAndConditions;
export const selectAcceptedTrackingTerms = (state) =>
  state.account.acceptedTrackingTerms;

export const selectIsLoadingTerms = (state) => state.account.isLoadingTerms;

export const selectIsMitrailSite = (state) => ORIGIN.includes("mitrail");
export const selectIsSafeTracksSite = (state) =>
  ORIGIN.includes("safetracksgps");
export const selectIsBlueFoxSite = (state) => ORIGIN.includes("bluefox");
export const selectIsFleetbridgeSite = (state) =>
  ORIGIN.includes("fleetbridge");

export const selectIsHostnameValid = createSelector(
  [selectDocuments],
  (documents) => {
    return documents?.serviceDomain?.includes(ORIGIN);
  }
);

// USER MAP SETTINGS
export const selectUserMapSettings = (state) => state.account.mapSettings;
export const selectIsAutoFit = (state) => state.account.mapSettings.autoFit;
export const selectIsLiveMap = (state) => state.account.mapSettings.liveMap;
export const selectIsShowingTag = (state) => state.account.mapSettings.showTags;
export const selectTags = (state) => state.account.mapSettings.tags;
export const selectIsShowingTrafficLayer = (state) =>
  state.account.mapSettings.trafficLayer;
export const selectIsClustering = (state) => state.account.mapSettings.cluster;

export const selectTagsAsArray = createSelector([selectTags], (tags) => {
  const values = [];
  Object.entries(tags).forEach(([key, value]) => {
    if (value) values.push(key);
  });
  return values;
});

export const selectIsShowingTable = (state) =>
  state.account.mapSettings.tableView;
export const selectIsShowingClusterCard = (state) =>
  state.account.mapSettings.showCard;
export const selectIsShowingGeoplaces = (state) =>
  state.account.mapSettings.geoplaces;

export const buildSelectIsShowingTable = (location) =>
  createSelector([selectIsShowingTable], (isShowingTable) => {
    const isRouted =
      !location.pathname.includes("/Details") &&
      !location.pathname.includes("/Trips");

    return isRouted && isShowingTable;
  });
