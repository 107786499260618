import React from "react";
import { TITLE } from "configurations/appConfig";
import "./styles.css";

export default function ApplicationLoader() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100dvh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="loader">
        <div className="car">
          <div className="car-part wheel-wells"></div>
          <div className="car-part wheels"></div>
          <div className="car-part body"></div>
          <div className="car-part roof"></div>
          <div className="car-part windows"></div>
          <div className="car-part lights"></div>
          <div className="smoke"></div>
          <div className="smoke"></div>
          <div className="smoke"></div>
        </div>
      </div>
      <h1 style={{ paddingTop: 16 }}>{TITLE}</h1>
    </div>
  );
}
