import client from 'configurations/fetch';

export const getAllCompanies = () => {
  return client.get('/companies');
};

export const getMyCompany = () => {
  return client.get(`/companies/self`);
};

export const updateCompany = (cid, company) => {
  return client.put(`/companies/${cid}`, company);
};

export const getCompanyActionCodeOverride = (cid) => {
  return client.get(`/companies/CompanyActionCodeOverride/${cid}`);
};
