import entitiesReducer from "./entities";
import uiReducer from "./ui";
import mapSlice from "./map/slice";
import stripeReducer from "./stripe";
import dashboardReducer from "./dashboard";
import accountSlice from "./account/slice";
import deviceEventsSlice from "./entities/deviceEvents/slice";
import deviceSlice from "./device/slice";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { createAction } from "@reduxjs/toolkit";
import storageLocal from "redux-persist/lib/storage";

const entitiesPersist = {
  key: "entities",
  storage: storageLocal,
  whitelist: ["assets", "assetGroups", "users", "companies", "schedules"],
  blacklist: ["trips"], // maybe special case this only for canadream?
};

const devicePersist = {
  key: "device",
  storage: storageLocal,
  whitelist: ["data"],
};

const uiPersist = {
  key: "ui",
  storage: storageLocal,
  whitelist: ["assets"],
};

const accountPersist = {
  key: "account",
  storage: storageLocal,
  blacklist: ["error", "isLoading"],
};

const stripePersist = {
  key: "stripe",
  storage: storageLocal,
  whitelist: ["cart", "customer"],
};

const appReducer = combineReducers({
  entities: persistReducer(entitiesPersist, entitiesReducer),
  ui: persistReducer(uiPersist, uiReducer),
  account: persistReducer(accountPersist, accountSlice.reducer),
  stripe: persistReducer(stripePersist, stripeReducer),
  dashboard: dashboardReducer,
  map: mapSlice.reducer,
  deviceEvents: deviceEventsSlice.reducer,
  device: persistReducer(devicePersist, deviceSlice.reducer),
});

export default function rootReducer(state, action) {
  // if (action.type === 'LOGOUT') {
  //   state = undefined;
  // }

  return appReducer(state, action);
}

export const lOGOUT = createAction("LOGOUT");
export const PURGE = createAction("PURGE");
