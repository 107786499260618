import { createSlice, createAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";
import {
  defaultGranularLoadingReducers,
  defaultGranularLoadingState,
} from "store/defaults";
import purge from "store/purge";

const initialState = {
  ...defaultGranularLoadingState,
};

export default createSlice({
  name: "ASSETS_ENTITY",
  initialState,
  reducers: {
    ...defaultGranularLoadingReducers,

    success: {
      reducer: (state, { payload, meta }) => {
        const assets = payload?.entities?.assets || state.data;

        if (state.data && Object.keys(state.data).length > 0) {
          state.data = Object.keys(assets).reduce((acc, cur) => {
            const currentAsset = assets[cur];

            // Check if solutionPlanName exists and contains "- Annual"
            if (
              currentAsset.solutionPlanName &&
              currentAsset.solutionPlanName.includes("- Annual")
            ) {
              // Remove "- Annual" from solutionPlanName
              currentAsset.solutionPlanName = currentAsset.solutionPlanName
                .replace("- Annual", "")
                .trim();
            }

            acc[cur] = merge({}, state.data[cur], currentAsset);
            return acc;
          }, {});
        } else {
          state.data = assets;
        }

        state.isLoading = { ...state.isLoading, [meta.initiatorType]: false };
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },

    // used for updating asset status for realtime and for asset status from the api every minute
    // this is so the app will work as it did before even if the realtime assets are not working or are shut off
    successAssetsStatus: (state, { payload }) => {
      const assetUpdates = payload.entities.assets;
      const assets = state.data;

      const deepMerge = Object.keys(assets).reduce((acc, cur) => {
        const assetStatus = assetUpdates[cur];
        const currentAsset = assets[cur];

        acc[cur] = merge({}, currentAsset, assetStatus);

        return acc;
      }, {});

      state.data = deepMerge;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_ASSETS = createAction("FETCH_ASSETS", (payload, meta) => ({
  payload,
  meta,
}));

export const UPDATE_ASSET = createAction("UPDATE_ASSET", (payload, meta) => ({
  payload,
  meta,
}));

export const UPDATE_ASSET_VEHICLE_ODOMETER = createAction(
  "UPDATE_ASSET_VEHICLE_ODOMETER",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const UPDATE_ASSET_ENGINE_HOURS = createAction(
  "UPDATE_ASSET_ENGINE_HOURS",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const SHARE_ASSET = createAction("SHARE_ASSET", (payload, meta) => ({
  payload,
  meta,
}));
