import Languages from "../../utilities/enumerations/Languages";
import MobileApplications from "../../utilities/enumerations/MobileApplications";
import client from "configurations/fetch";

// GET

export const getMyUser = () => {
  return client.get("/users/self");
};

export const getLBService = () => {
  return client.get(`/users/lbs`);
};

export const getMyAvatar = () => {
  return client.get(`/users/self/avatar`);
};

export const getUsers = (cid) => {
  return client.get(`/users/GetUsers/${cid}`);
};

export const getUser = (uid) => {
  return client.get(`/users/${uid}`);
};

export const getAssetsAssignedToUser = (uid) => {
  return client.get(`/assets/assetlist/${uid}`);
};

export const getUserMapSettings = (uid) => {
  return client.get(`/users/${uid}/mapsettings`);
};

export const getNewAuthorizationToken = () => {
  return client.get(`/token/refresh`);
};

// POST

export const registerNewUser = (request) => {
  return client.post("/register/createNewUser", request);
};

export const resendRegisterEmail = (request) => {
  return client.post("/register/resendRegistrationEmail", request);
};

export const setPassword = ({
  userName: username,
  oldPassword,
  newPassword,
}) => {
  const data = {
    NewPassword: newPassword,
    OldPassword: oldPassword,
    UserName: username,
  };

  return client.post(`/users/setpassword`, data);
};

export const resetPassword = (username) => {
  const data = {
    UserName: username,
  };

  return client.post(`/users/resetpassword`, data);
};

export const acceptTermsAndConditions = (uid, urlOfDocument) => {
  const data = { uid, urlOfDocument };

  return client.post(`/users/termsandconditions/accept`, data);
};

export const rejectTermsAndConditions = (uid, urlOfDocument) => {
  const data = { uid, urlOfDocument };

  return client.post(`/users/termsandconditions/reject`, data);
};

export const updateUserAccountState = (uid, accountState) => {
  const data = { uid, accountState };

  return client.post(`/users/updateUserAccountState`, data);
};

export const acceptTrackingDeviceAgreement = (uid, urlOfDocument) => {
  const data = { uid, urlOfDocument };

  return client.post(`/users/trackingdeviceagreement/accept`, data);
};

export const rejectTrackingDeviceAgreement = (uid, urlOfDocument) => {
  const data = { uid, urlOfDocument };

  return client.post(`/users/trackingdeviceagreement/reject`, data);
};

export const login = (
  username,
  password,
  appId = MobileApplications.FLEETBridge
) => {
  var loginRequest = {
    UserName: username,
    Password: password,
    AppId: appId,
  };

  return client.post(`/users/login`, loginRequest);
};

export const assignAssetsToUser = (request) => {
  return client.post(`/users/AssignAssetsToUser`, request);
};

export const assignGroupsToUser = (request) => {
  return client.post(`/users/AssignGroupsToUser`, request);
};

export const updateUserNameEmail = (uid, newUserName) => {
  return client.post(`/users/${uid}/UpdateUserNameEmail`, newUserName);
};

export const addUser = (user) => {
  return client.post(`/users/AddUser`, user);
};

export const updateUserTutorials = (uid, tutorials) => {
  return client.post(`/Users/UpdateUserTutorials`, { uid, tutorials });
};

export const updateUserMapSettings = (request) => {
  return client.post(`/users/UpdateMapSettings`, request);
};

// PUT

export const uploadAvatar = (uid, image) => {
  const userAvatar = {
    avatar: image,
    uid,
  };

  return client.put(`/users/${uid}/avatar`, userAvatar);
};

export const updateLanguage = (uid, language) => {
  let lang;
  switch (language) {
    case "fr":
      lang = Languages.French;
      break;

    case "pl":
      lang = Languages.PsuedoLanguage;
      break;

    default:
      lang = Languages.English;
      break;
  }
  return client.put(`/users/${uid}/language`, `${lang}`);
};

export const updateUser = (uid, user) => {
  return client.put(`/users/${uid}`, user);
};

// DELETE

export const deleteMyAvatar = () => {
  return client.delete(`/users/self/avatar`);
};

export const deleteUser = (uid) => {
  return client.delete(`/users/DeleteUser/${uid}`);
};
