import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { isMobile } from "react-device-detect";
import { sendToMobileInterface } from "utilities/mobile/interface";
import { RESEND_REGISTER_EMAIL } from "store/account/slice";

export default function RegisterSuccess({ form }) {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { watch } = form;
  const formValues = watch();
  const [sentEmail, setSentEmail] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    if (isMobile) {
      sendToMobileInterface("success", { email: formValues.email });
    } else {
      history.push("/register");
    }
    setSentEmail(false);
  };

  const dispatchGotoLogin = useCallback(() => {
    if (isMobile) {
      sendToMobileInterface("success", { email: formValues.email });
    } else {
      history.push("/login");
    }
  }, [history, formValues]);

  const dispatchResendRegisterEmail = useCallback(() => {
    const { email } = formValues;
    setSendingEmail(true);
    dispatch(
      RESEND_REGISTER_EMAIL(
        { email },
        {
          onSuccess: () => {
            setSendingEmail(false);
            setSentEmail(true);
          },
          onError: () => {
            setSendingEmail(false);
          },
        }
      )
    );
  }, [dispatch, formValues, setSentEmail]);

  return (
    <Dialog
      open
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="register-success-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: theme.shape.borderRadius,
          maxWidth: "sm",
          width: "100%",
        },
      }}
    >
      <DialogTitle
        id="register-success-dialog-title"
        sx={{ textAlign: "center", pb: 0 }}
      >
        {i18n._(t`Registration Successful`)}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 3,
          }}
        >
          <CheckCircleOutlineIcon
            color="success"
            sx={{ fontSize: 84, mb: 2 }}
          />
          <Typography
            variant="h6"
            sx={{ mb: 2, fontWeight: 600, textAlign: "center" }}
          >
            {i18n._(t`Your account has been created!`)}
          </Typography>
          {sendingEmail ? (
            <CircularProgress sx={{ my: 2 }} />
          ) : !sentEmail ? (
            <Typography sx={{ textAlign: "center" }}>
              {i18n._(
                t`An email has been sent to ${formValues.email}. If you don't receive the email in a few minutes, click`
              )}{" "}
              <Link
                component="button"
                variant="body2"
                onClick={dispatchResendRegisterEmail}
              >
                {i18n._(t`here`)}
              </Link>{" "}
              {i18n._(t`to resend it.`)}
            </Typography>
          ) : (
            <Typography sx={{ textAlign: "center" }}>
              {i18n._(
                t`Email sent! If you are still not receiving the email, please contact our support at`
              )}{" "}
              <Link
                component="button"
                variant="body2"
                onClick={() =>
                  window.open("mailto:support@mitrail.com", "_blank")
                }
              >
                {i18n._(t`support@mitrail.com`)}
              </Link>
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
        <Button onClick={handleClose} color="primary">
          {i18n._(t`Close`)}
        </Button>
        <Button onClick={dispatchGotoLogin} color="primary" variant="contained">
          {i18n._(t`Go to Login`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
