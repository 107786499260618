import React from "react";
import LoginForm from "forms/Login/Login";
import Box from "@mui/material/Box";

export default function LoginContainer() {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ margin: "auto" }}>
        <LoginForm />
      </Box>
    </Box>
  );
}
