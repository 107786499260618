import { takeLatest, put, select } from "redux-saga/effects";
import slice, { FETCH_DISTANCE_COMPANY } from "store/dashboard/distance/slice";
import { getDistanceForCompany } from "resources/services/dashboardService";
import { selectMyUser } from "store/account/selectors";

function* fetch({ payload, meta }) {
  const { lastN } = payload;
  const { error, success, fetching } = slice.actions;
  const { cid } = yield select(selectMyUser);

  yield put(fetching());

  try {
    const response = yield getDistanceForCompany(cid, lastN);
    yield put(success(response));
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchDistanceCompany() {
  yield takeLatest(FETCH_DISTANCE_COMPANY.type, fetch);
}
