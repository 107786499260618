import { useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { selectMyUser } from "store/account/selectors";
import { firestore } from "configurations/firebase";
import throttle from "lodash/throttle";
import assetEntitiesSlice from "store/entities/assets/slice";

export default function useAssetsRealtime() {
  const { cid } = useSelector(selectMyUser);
  const dispatch = useDispatch();

  const { successAssetsStatus } = assetEntitiesSlice.actions;

  // only dispatch new position updates every 2 seconds
  // TODO: only update if this data was more recent
  const throttledDispatch = throttle(
    (assets) => dispatch(successAssetsStatus({ entities: { assets } })),
    2000
  );

  useEffect(() => {
    if (!firestore || !cid) return;

    const q = collection(firestore, "companies", cid.toString(), "assets");

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const assets = {};

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        assets[data.aid] = data;
      });

      // Use the throttled dispatch function here
      throttledDispatch(assets);
    });

    return () => unsubscribe();
  }, [successAssetsStatus, cid, dispatch, throttledDispatch]);
}
